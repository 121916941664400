const Reviews = {
    REVIEW_1: {
        name: 'Alex Johnson',
        quote: "ScreenKeep is the perfect tool for anyone looking to display their web content on Chromecast. It's easy to use and works flawlessly.",
    },
    REVIEW_2: {
        name: 'Jamie Patel',
        quote: "I've been using ScreenKeep to showcase my Google Slideshows on my TV, and it's been fantastic. It's simple, effective, and just what I needed.",
    },
    REVIEW_3: {
        name: 'Megan Thompson',
        quote: "ScreenKeep is a must-have for DIYers like me. It's affordable and works great for displaying my custom web pages.",
    },
    REVIEW_4: {
        name: 'Chris Nguyen',
        quote: "I love how ScreenKeep makes it easy to keep my Chromecast display up-to-date. It's been a reliable tool for all my web-based needs.",
    },
    REVIEW_5: {
        name: 'Taylor Williams',
        quote: "ScreenKeep has been a game-changer for showing my website on Chromecast. It's straightforward, and it just works.",
    },
    REVIEW_6: {
        name: 'Patricia Sanchez',
        quote: "Using ScreenKeep to run my webpage on Chromecast has been a breeze. It's a reliable app that does exactly what I need.",
    },
    REVIEW_7: {
        name: 'David Miller',
        quote: "ScreenKeep is the best app I've found for displaying web pages on Chromecast. It's free to try and works perfectly for my needs!",
    },
    REVIEW_8: {
        name: 'Lisa Kim',
        quote: "ScreenKeep makes displaying my web content on TV effortless. It's a great tool that's both affordable and effective.",
    },
    REVIEW_9: {
        name: 'Ethan Rodriguez',
        quote: "ScreenKeep is ideal for anyone looking to display web pages on Chromecast. It's simple to use and gets the job done right.",
    },
    REVIEW_10: {
        name: 'Ben Mayglen',
        quote: "I've started installing ScreenKeep for my clients who need a simple solution to display web content on Chromecasts. It's been a hit - easy to set up and reliable!",
    },
};

export default Reviews;
