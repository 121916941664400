import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, IconButton, Fade } from "@mui/material";
import { ArrowBack, ArrowForward, FormatQuote } from "@mui/icons-material";
import Reviews from "../../constants/reviews";

type Review = {
    name: string;
    quote: string;
};

export default function ReviewCarousel(): JSX.Element {
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const intervalRef = useRef<number | null>(null);

    const reviewsArray = Object.values(Reviews);

    const resetInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = window.setInterval(nextReview, 5000);
    };

    const nextReview = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviewsArray.length);
            setFade(true);
        }, 200);
        resetInterval();
    };

    const prevReview = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentReviewIndex((prevIndex) =>
                prevIndex === 0 ? reviewsArray.length - 1 : prevIndex - 1
            );
            setFade(true);
        }, 200);
        resetInterval();
    };

    useEffect(() => {
        resetInterval();
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const { name, quote } = reviewsArray[currentReviewIndex] as Review;

    return (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ p: 2, pt: 0 }}>
            <IconButton onClick={prevReview}>
                <ArrowBack />
            </IconButton>
            <Fade in={fade} timeout={500}>
                <Box sx={{ textAlign: "center", mx: 4, position: 'relative' }}>
                    <FormatQuote
                        sx={{
                            fontSize: 60,
                            color: 'rgba(0, 0, 0, 0.1)',
                            position: 'absolute',
                            top: -20,
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    />
                    <Typography variant="h6" component="p" sx={{ mb: 2, mt: 6, textTransform: 'none' }}>
                        {quote}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontStyle: "italic" }}>
                        - {name}
                    </Typography>
                </Box>
            </Fade>
            <IconButton onClick={nextReview}>
                <ArrowForward />
            </IconButton>
        </Box>
    );
}
