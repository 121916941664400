import { Box, Button, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { HeadFC, PageProps } from "gatsby";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import TelevisionSvg from "../components/svg/television";
import Colors from "../constants/colors";
import LayoutType from "../constants/layout-type";
import ReviewCarousel from "../components/reviews/reviewCarousel";
import { Link, navigate } from "gatsby";

const IndexPage: React.FC<PageProps> = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <Box
                sx={{
                    position: "relative",
                    paddingTop: isMobile ? theme.spacing(8) : theme.spacing(4),
                    paddingLeft: isMobile ? 0 : theme.spacing(2),
                    paddingRight: isMobile ? 0 : theme.spacing(2),
                    backgroundColor: isMobile ? "rgba(255, 255, 255, 0.1)" : "transparent",
                }}
                alignContent="center"
                alignItems="center"
                flex={1}
            >
                <Container>
                    <Box maxWidth="lg">
                        {isMobile && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    zIndex: -1,
                                    opacity: 0.2,
                                }}>
                                <TelevisionSvg sx={{
                                    position: "absolute",
                                    bottom: "-121px",
                                    right: 0,
                                    maxHeight: "100%"
                                }} />
                            </Box>
                        )}
                        <Grid container spacing={0} alignItems="center" style={{ textAlign: isMobile ? "center" : "left" }}>
                            <Grid item xs={12} md={8} lg={7} alignItems={isMobile ? 'center' : 'left'} style={{ display: 'flex', flexDirection: 'column', zIndex: 99 }}>
                                {!isMobile &&
                                    <Typography component="h1" variant="h6" gutterBottom>
                                        <FormattedMessage id="screenKeep" defaultMessage="Screen Keep Digital Signage" />
                                    </Typography>
                                }
                                <Typography variant="h1" gutterBottom mt={isMobile ? 8 : 0} align={isMobile ? "center" : "left"} sx={{
                                    color: Colors.BLACK,
                                    fontSize: {
                                        xs: "2.84rem",
                                        md: "4.25rem"
                                    }
                                }}>
                                    <FormattedMessage id="mainTitle" defaultMessage="Digital Signage Android TV App" />
                                </Typography>
                                <Typography component="h2" variant="h6" pb={3} gutterBottom>
                                    &nbsp;<FormattedMessage id="subTitle" defaultMessage="Simple Signage For Web Pages - No Monthly Fees Required&nbsp;" />
                                </Typography>
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        href="https://play.google.com/store/apps/details?id=com.screenkeep.screenkeep"
                                        sx={{ marginBottom: isMobile ? 4 : 0 }}
                                    >
                                        <FormattedMessage id="downloadButton" defaultMessage="Download Screen Keep On Google Play" />
                                    </Button>
                                </Box>
                            </Grid>
                            {!isMobile && (
                                <Grid item xs={12} md={4} lg={5}>
                                    <Box minWidth="90%" width="calc(100% + 240px)" minHeight="615px" maxHeight="750px" marginLeft="-100px" marginRight="-100px">
                                        <TelevisionSvg />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Box py={isMobile ? 2 : 6} sx={{
                backgroundColor: Colors.BACKGROUND_COLOR_DARK
            }}>
                <Container>
                    <Grid container justifyContent="center">
                        <Typography component="h2" variant="h5" gutterBottom align="center" sx={{
                            marginBottom: 0,
                            color: Colors.BACKGROUND,
                            fontSize: {
                                xs: "1.2rem",
                                md: "1.5rem"
                            }
                        }}>
                            <FormattedMessage id="callToAction" defaultMessage="Download Screen Keep, Enter a Webpage URL, and Display Your Content Today For Free!" />
                        </Typography>
                    </Grid>
                </Container>
            </Box>
            <Box py={isMobile ? 10 : 8} sx={{ backgroundColor: Colors.BACKGROUND }}>
                <Container>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" gutterBottom align="center" sx={{ color: Colors.BACKGROUND_COLOR_DARK, fontSize: "1.1rem", fontWeight: "800" }}>
                                <FormattedMessage id="simpleDigitalSignage.title" defaultMessage="Simple Digital Signage" />
                            </Typography>
                            <Typography align="center">
                                <FormattedMessage id="simpleDigitalSignage.description" defaultMessage="Transform any Android TV into a digital signage solution with ease, and display web content seamlessly." />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" gutterBottom align="center" sx={{ color: Colors.BACKGROUND_COLOR_DARK, fontSize: "1.1rem", fontWeight: "800" }}>
                                <FormattedMessage id="autoStartWebpage.title" defaultMessage="Auto-Start Webpage" />
                            </Typography>
                            <Typography align="center">
                                <FormattedMessage id="autoStartWebpage.description" defaultMessage="Set a webpage to automatically start when the Android TV boots up, without any manual intervention." />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" gutterBottom align="center" sx={{ color: Colors.BACKGROUND_COLOR_DARK, fontSize: "1.1rem", fontWeight: "800" }}>
                                <FormattedMessage id="wideCompatibility.title" defaultMessage="Wide Compatibility" />
                            </Typography>
                            <Typography align="center">
                                <FormattedMessage id="wideCompatibility.description" defaultMessage="Screen Keep works on Android devices supporting Google Play Services including Chromecast, Google TVs, and other Android TV Boxes, giving you maximum flexibility." />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" gutterBottom align="center" sx={{ color: Colors.BACKGROUND_COLOR_DARK, fontSize: "1.1rem", fontWeight: "800" }}>
                                <FormattedMessage id="automaticRefresh.title" defaultMessage="Automatic Refresh" />
                            </Typography>
                            <Typography align="center">
                                <FormattedMessage id="automaticRefresh.description" defaultMessage="Keep your content up-to-date with the automatic refresh feature, ensuring your displayed content is always current." />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" gutterBottom align="center" sx={{ color: Colors.BACKGROUND_COLOR_DARK, fontSize: "1.1rem", fontWeight: "800" }}>
                                <FormattedMessage id="easyCustomization.title" defaultMessage="Easy Customization" />
                            </Typography>
                            <Typography align="center">
                                <FormattedMessage id="easyCustomization.description" defaultMessage="Customize your display with ease, thanks to Screen Keep's user-friendly interface and straightforward settings." />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" gutterBottom align="center" sx={{ color: Colors.BACKGROUND_COLOR_DARK, fontSize: "1.1rem", fontWeight: "800" }}>
                                <FormattedMessage id="onlineManagement.title" defaultMessage="Online Management" />
                            </Typography>
                            <Typography align="center">
                                <FormattedMessage id="onlineManagement.description" defaultMessage="Manage your digital signage online, enabling you to update content and settings from anywhere with an internet connection." />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" gutterBottom align="center" sx={{ color: Colors.BACKGROUND_COLOR_DARK, fontSize: "1.1rem", fontWeight: "800" }}>
                                <FormattedMessage id="freeEvaluation.title" defaultMessage="Free One-Week Evaluation" />
                            </Typography>
                            <Typography align="center">
                                <FormattedMessage id="freeEvaluation.description" defaultMessage="Test out Screen Keep with a free one-week evaluation, and experience its features before making a purchase." />
                                <br /><br /><br />
                                <b>
                                    <FormattedMessage id="freeEvaluation.description2" defaultMessage="Free online management included for simple setup" />
                                </b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" gutterBottom align="center" sx={{ color: Colors.BACKGROUND_COLOR_DARK, fontSize: "1.1rem", fontWeight: "800" }}>
                                <FormattedMessage id="affordablePurchase.title" defaultMessage="Affordable One-Time Purchase" />
                            </Typography>
                            <Typography align="center">
                                <FormattedMessage id="affordablePurchase.description" defaultMessage="No monthly subscription required! Screen Keep is available as an affordable one-time purchase for a single device." />
                                <br /><br />
                                <b>
                                    <FormattedMessage id="affordablePurchase.description2" defaultMessage="Three months of online management included" />
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box py={isMobile ? 10 : 8} sx={{ backgroundColor: Colors.BACKGROUND_DARK }}>
                <Container>
                    <ReviewCarousel />
                </Container>
            </Box>
            <Box py={isMobile ? 10 : 8} pb={4} sx={{ textAlign: 'center' }}>
                <Container>
                    <Typography variant="h4" sx={{ mb: 5, color: Colors.PRIMARY }}>
                        Ready to Get Started with Simple Digital Signage?
                    </Typography>

                    <Button
                        component={Link}
                        to="/auth/register"
                        variant="contained"
                        color="primary"
                        underline="none"
                    >
                        Register For ScreenKeep Today
                    </Button>
                </Container>
            </Box>
        </>
    )
}

IndexPage.layoutType = LayoutType.DefaultFullWidth;

export default IndexPage

export const Head: HeadFC = () => (
    <>
        <title>ScreenKeep - Digital Signage Android TV App</title>
        <meta name="description" content="Subscription free digital signage solution for Android TV. Transform any Android TV into a digital signage solution with ease, and display web content seamlessly." />
        <meta name="keywords" content="Screen Keep,ScreenKeep,Cheap Digital Signage,Free Digital Signage,Web Page Digital Signage,Show Web Page On TV,Digital Signage,Android TV Digital Signage,Web Content,Screen Keep App" />
    </>
)