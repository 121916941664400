import { Box, styled, SxProps } from "@mui/material";
import * as React from "react";

interface TelevisionSvgProps {
    sx?: SxProps;
}

const Svg = ({ sx, ...rest }: TelevisionSvgProps) => {
    return (
        <Box component="svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="es6YhZayTjc1" viewBox="0 0 600 600" shapeRendering="geometricPrecision" textRendering="geometricPrecision" sx={sx}>
                <defs>
                    <linearGradient id="es6YhZayTjc3-fill" x1="119.25" y1="310.05" x2="119.25" y2="307.44" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                        <stop id="es6YhZayTjc3-fill-0" offset="3%" stopColor="#9f9fa1" />
                        <stop id="es6YhZayTjc3-fill-1" offset="100%" stopColor="#818182" />
                    </linearGradient>
                    <linearGradient id="es6YhZayTjc4-fill" x1="280.75" y1="310.05" x2="280.75" y2="307.44" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                        <stop id="es6YhZayTjc4-fill-0" offset="3%" stopColor="#9f9fa1" />
                        <stop id="es6YhZayTjc4-fill-1" offset="100%" stopColor="#818182" />
                    </linearGradient>
                    <linearGradient id="es6YhZayTjc5-fill" x1="107.08" y1="324.66" x2="107.08" y2="321.67" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                        <stop id="es6YhZayTjc5-fill-0" offset="3%" stopColor="#b8b8ba" />
                        <stop id="es6YhZayTjc5-fill-1" offset="100%" stopColor="#9d9d9e" />
                    </linearGradient>
                    <linearGradient id="es6YhZayTjc6-fill" x1="292.92" y1="324.66" x2="292.92" y2="321.67" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                        <stop id="es6YhZayTjc6-fill-0" offset="3%" stopColor="#b8b8ba" />
                        <stop id="es6YhZayTjc6-fill-1" offset="100%" stopColor="#9d9d9e" />
                    </linearGradient>
                    <linearGradient id="es6YhZayTjc7-fill" x1="200" y1="309.68" x2="200" y2="293.58" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                        <stop id="es6YhZayTjc7-fill-0" offset="3%" stopColor="#b8b8ba" />
                        <stop id="es6YhZayTjc7-fill-1" offset="100%" stopColor="#9d9d9e" />
                    </linearGradient>
                    <linearGradient id="es6YhZayTjc8-fill" x1="200" y1="324.67" x2="200" y2="293.62" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                        <stop id="es6YhZayTjc8-fill-0" offset="14%" stopColor="#e1e1e3" />
                        <stop id="es6YhZayTjc8-fill-1" offset="38%" stopColor="#cfcfd1" />
                        <stop id="es6YhZayTjc8-fill-2" offset="100%" stopColor="#bbbbbc" />
                    </linearGradient>
                    <linearGradient id="es6YhZayTjc9-fill" x1="177.05" y1="212.65" x2="149.35" y2="-25.39" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                        <stop id="es6YhZayTjc9-fill-0" offset="2%" stopColor="#efefef" />
                        <stop id="es6YhZayTjc9-fill-1" offset="9%" stopColor="#e4e4e6" />
                        <stop id="es6YhZayTjc9-fill-2" offset="90%" stopColor="#f7f7f7" />
                        <stop id="es6YhZayTjc9-fill-3" offset="99%" stopColor="#e4e4e6" />
                    </linearGradient>
                    <linearGradient id="es6YhZayTjc10-fill" x1="14.41" y1="369.529999" x2="274.13" y2="266.289999" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                        <stop id="es6YhZayTjc10-fill-0" offset="0%" stopColor="#585b61" />
                        <stop id="es6YhZayTjc10-fill-1" offset="85%" stopColor="#484b4e" />
                        <stop id="es6YhZayTjc10-fill-2" offset="100%" stopColor="#4c4f54" />
                    </linearGradient>
                </defs>
                <g transform="matrix(1.766751 0 0 1.625776-53.3502-73.439663)">
                    <path d="M115.24,307.33l-.58,1c-.199013.327876-.205383.737685-.016655,1.071588s.543109.539808.926655.538412h3.43c1.40764-.000773,2.811544-.144849,4.19-.43v0c.532002-.12642.888001-.627449.832338-1.171425s-.505757-.962522-1.052338-.978575Z" fill="url(#es6YhZayTjc3-fill)" />
                    <path d="M284.76,307.33h-7.68c-.546581.016053-.996676.434599-1.052338.978575s.300336,1.045005.832338,1.171425v0c1.378456.285151,2.78236.429227,4.19.43h3.39c.383546.001396.737927-.204509.926655-.538412s.182358-.743712-.016655-1.071588Z" fill="url(#es6YhZayTjc4-fill)" />
                    <path d="M102.46,321.54l-.67,1.11c-.228616.37054-.242436.834832-.036263,1.218313s.601086.628025,1.036263.641687h3.9c1.619127.000562,3.23411-.163617,4.82-.49v0c.642732-.115357,1.088371-.706004,1.022876-1.355713s-.620052-1.13953-1.272876-1.124287Z" fill="url(#es6YhZayTjc5-fill)" />
                    <path d="M297.54,321.54h-8.84c-.652824-.015243-1.207381.474577-1.272876,1.124287s.380144,1.240356,1.022876,1.355713v0c1.58589.326383,3.200873.490562,4.82.49h3.9c.435177-.013662.83009-.258205,1.036263-.641687s.192353-.847773-.036263-1.218313Z" fill="url(#es6YhZayTjc6-fill)" />
                    <path d="M285.79,307.36l-.9-1.57c-.39804-.69163-1.090609-1.163167-1.88-1.28l-79.92-11.59h-6.17L117,304.51c-.789391.116833-1.48196.58837-1.88,1.28l-.9,1.57c-.202047.331225-.194268.749427.019958,1.072908s.59623.493853.980042.437092l84.83-7l84.83,7c.368194.018649.716785-.166798.907076-.482557s.191416-.710606.002924-1.027443Z" fill="url(#es6YhZayTjc7-fill)" />
                    <path d="M298.21,321.17l-1.86-3.07c-.757868-1.255431-1.99784-2.144741-3.43-2.46L210.85,297.7c-1.97994-.43327-3.698365-1.653496-4.76-3.38l-.9-1.46c-.214767-.349014-.590411-.566887-1-.58h-3.09c-.443994.002695-.85085.248344-1.06.64-.20915-.391656-.616006-.637305-1.06-.64h-3.09c-.409589.013113-.785233.230986-1,.58l-.9,1.46c-1.061635,1.726504-2.78006,2.94673-4.76,3.38l-82.07,17.94c-1.43216.315259-2.672132,1.204569-3.43,2.46l-1.86,3.07c-.228616.37054-.242436.834832-.036263,1.218313s.601086.628025,1.036263.641687h3.9c1.619127.000562,3.23411-.163617,4.82-.49L200,304.31l88.45,18.22c1.58589.326383,3.200873.490562,4.82.49h3.9c.439165-.001707.843523-.239321,1.058732-.622145s.208055-.851774-.018732-1.227855Z" fill="url(#es6YhZayTjc8-fill)" />
                    <rect width="326.5" height="188.09" rx="2.73" ry="2.73" transform="translate(36.75 105.49)" fill="url(#es6YhZayTjc9-fill)" stroke="#e6e6e6" strokeMiterlimit="10" />
                    <rect width="172.51" height="310.93" rx="1.42" ry="1.42" transform="matrix(0 1-1 0 355.46 113.27)" fill="url(#es6YhZayTjc10-fill)" />
                </g>
        </Box>
    );
};


const TelevisionSvg = styled(Svg)({});

export default TelevisionSvg;